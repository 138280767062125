import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import data from '../config/data/data.json';
import style from '../config/variables/style.js';

class Charts extends Component {
  render() {
    const currentChairsByParty = [
      ['MDB', 12],
      ['PDT', 7],
      ['PP', 6],
      ['DEM', 6],
      ['PT', 4],
      ['PSOL', 4],
      ['PRB', 4],
      ['PSD', 3],
      ['PSDB', 3],
      ['SOLIDARIEDADE', 2],
      ['PRP', 2],
      ['PR', 2],
      ['DC', 2],
      ['PSB', 2],
      ['PODE', 2],
      ['PSL', 2],
      ['PSC', 2],
      ['PHS', 1],
      ['PC do B', 1],
      ['PTB', 1],
      ['PPS', 1],
      ['AVANTE', 1],
      ['NOVO', 0],
      ['PATRI', 0],
      ['PMB', 0],
      ['PROS', 0],
      ['PRTB', 0],
      ['PTC', 0]
    ];

    const electedChairsByParty = _.orderBy(
      Object.entries(_.groupBy(data, 'candidateData.partido.sigla')).map(
        item => [item[0], item[1].length]
      ),
      x => x[1],
      'desc'
    );

    const electedChairsByOccupation = _.orderBy(
      Object.entries(_.groupBy(data, 'candidateData.ocupacao')).map(item => [
        item[0],
        item[1].length
      ]),
      x => x[1],
      'desc'
    );

    const electedChairsByEducation = _.orderBy(
      Object.entries(_.groupBy(data, 'candidateData.grauInstrucao')).map(
        item => [item[0], item[1].length]
      ),
      x => x[1],
      'desc'
    );

    const chart1Data = {
      labels: electedChairsByParty.map(item => item[0]),
      datasets: [
        {
          data: electedChairsByParty.map(item => item[1]),
          backgroundColor: electedChairsByParty.map(() => style.colors.green)
        }
      ]
    };

    const chart2Data = {
      labels: currentChairsByParty.map(item => item[0]),
      datasets: [
        {
          data: currentChairsByParty.map(item => item[1]),
          backgroundColor: currentChairsByParty.map(() => style.colors.blue)
        }
      ]
    };

    const diff = currentChairsByParty
      .map(item => {
        const newChairs = item[1];
        const oldChairs = electedChairsByParty.filter(
          x => x[0] === item[0]
        )[0][1];
        return [item[0], oldChairs - newChairs];
      })
      .sort((a, b) => (a[1] > b[1] ? -1 : 1));

    const chart3Data = {
      labels: diff.map(item => item[0]),
      datasets: [
        {
          data: diff.map(item => item[1]),
          backgroundColor: diff.map(
            item => (item[1] > 0 ? style.colors.green : style.colors.red)
          )
        }
      ]
    };

    const chart4Data = {
      labels: electedChairsByOccupation.map(item => item[0]),
      datasets: [
        {
          data: electedChairsByOccupation.map(item => item[1]),
          backgroundColor: electedChairsByOccupation.map(
            () => style.colors.purple
          )
        }
      ]
    };

    const chart5Data = {
      labels: electedChairsByEducation.map(item => item[0]),
      datasets: [
        {
          data: electedChairsByEducation.map(item => item[1]),
          backgroundColor: electedChairsByEducation.map(() => style.colors.grey)
        }
      ]
    };

    return (
      <div style={{ marginBottom: 40 }}>
        <Segment>
          <Bar
            data={chart1Data}
            options={{
              title: {
                display: true,
                text: 'Deputados eleitos por partido',
                fontSize: 25
              },
              legend: { display: false },
              scales: { xAxes: [{ ticks: { autoSkip: false } }] }
            }}
          />
        </Segment>
        <Segment>
          <Bar
            data={chart2Data}
            options={{
              title: {
                display: true,
                text: 'Deputados atuais por partido',
                fontSize: 25
              },
              legend: { display: false },
              scales: { xAxes: [{ ticks: { autoSkip: false } }] }
            }}
          />
        </Segment>
        <Segment>
          <Bar
            data={chart3Data}
            options={{
              title: {
                display: true,
                text: 'Variação de Deputados por partido',
                fontSize: 25
              },
              legend: { display: false },
              scales: { xAxes: [{ ticks: { autoSkip: false } }] }
            }}
          />
        </Segment>
        <Segment>
          <Bar
            data={chart4Data}
            options={{
              title: {
                display: true,
                text: 'Deputados eleitos por ocupação',
                fontSize: 25
              },
              legend: { display: false },
              scales: { xAxes: [{ ticks: { autoSkip: false } }] }
            }}
          />
        </Segment>
        <Segment>
          <Bar
            data={chart5Data}
            options={{
              title: {
                display: true,
                text: 'Deputados eleitos por grau de instrução',
                fontSize: 25
              },
              legend: { display: false },
              scales: { xAxes: [{ ticks: { autoSkip: false } }] }
            }}
          />
        </Segment>
      </div>
    );
  }
}

export default Charts;
