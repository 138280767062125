import React from 'react';
import { Divider, Header } from 'semantic-ui-react';

const pageName = page =>
  page === 'list'
    ? 'Parlamentares eleitos'
    : page === 'charts'
      ? 'Dados agregados'
      : null;

const MainHeader = ({ page }) => (
  <div>
    <Header style={{ margin: '0', fontSize: 40 }}>Eleições 2018</Header>
    <Header style={{ margin: '10px 0', fontSize: 25, fontWeight: 100 }}>
      Assembleia Legislativa do Estado do Rio de Janeiro
    </Header>
    <Header style={{ margin: '10px 0', fontSize: 20, fontWeight: 600 }}>
      {pageName(page)}
    </Header>
    <Divider style={{ marginBottom: 30 }} />
  </div>
);

export default MainHeader;
