import React from 'react';
import { Button, Card, Icon, Image, Label, Progress } from 'semantic-ui-react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import style from '../config/variables/style';

const LegislatorCard = ({ item, maxVotes, handleLegislatorClick }) => (
  <Card>
    <Card.Content>
      <Image
        src={item.candidateData.fotoUrl}
        style={{
          border: 'solid 5px white',
          boxShadow: '0 1px 1px 1px #aaa',
          height: 150
        }}
        rounded
      />
    </Card.Content>
    <Card.Content>
      <Card.Header>{item.nm}</Card.Header>
      <Card.Meta>{item.candidateData.partido.sigla}</Card.Meta>
    </Card.Content>
    <Card.Content>
      <NumberFormat
        value={parseInt(item.v)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
      />{' '}
      votos
      <Progress
        percent={(parseInt(item.v) * 100) / maxVotes}
        color="blue"
        style={{
          marginTop: 15,
          marginBottom: 10
        }}
        size="small"
      />
      <Card.Meta>{item.candidateData.descricaoTotalizacao}</Card.Meta>
    </Card.Content>
    <Card.Content style={{ textAlign: 'left' }}>
      <Card.Meta style={{ marginBottom: 15 }}>
        <Icon name="birthday" style={{ marginRight: 10 }} />{' '}
        {moment(item.candidateData.dataDeNascimento).format('DD/MM/YYYY')}
      </Card.Meta>
      <Card.Meta style={{ marginBottom: 15 }}>
        <Icon name="graduation" style={{ marginRight: 10 }} />{' '}
        {item.candidateData.grauInstrucao}
      </Card.Meta>
      <Card.Meta style={{ marginBottom: 15 }}>
        <Icon name="briefcase" style={{ marginRight: 10 }} />{' '}
        {item.candidateData.ocupacao}
      </Card.Meta>
      <Card.Meta style={{ marginBottom: 15 }}>
        <Icon name="map marker alternate" style={{ marginRight: 10 }} />{' '}
        Nascido(a) em {item.candidateData.nomeMunicipioNascimento}/
        {item.candidateData.sgUfNascimento}
      </Card.Meta>
      <Card.Meta style={{ marginBottom: 15 }}>
        <Icon name="credit card" style={{ marginRight: 10 }} />{' '}
        <NumberFormat
          value={item.candidateData.totalDeBens}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'Patrimônio de R$ '}
        />
      </Card.Meta>
      {item.candidateData.emails.map(emailAddress => (
        <Card.Meta key={emailAddress} style={{ marginBottom: 15 }}>
          <Icon name="envelope" style={{ marginRight: 10 }} /> {emailAddress}
        </Card.Meta>
      ))}
    </Card.Content>
    <Card.Content>
      <Label
        style={{
          backgroundColor: item.candidateData.st_REELEICAO
            ? style.colors.green
            : style.colors.purple,
          color: 'white'
        }}
      >
        {item.candidateData.st_REELEICAO ? 'Reeleição' : 'Novo mandato'}
      </Label>
    </Card.Content>
    <Card.Content style={{ backgroundColor: 'whitesmoke' }}>
      <Button
        fluid
        as="a"
        href={
          item.candidateData.eleicoesAnteriores.filter(
            item => item.nrAno === 2018
          )[0].txLink
        }
        target="_blank"
        color="green"
      >
        TSE
      </Button>
      {/*
        <Button fluid onClick={() => handleLegislatorClick(item.sqcand)}>
          Perfil
        </Button>
      */}
    </Card.Content>
  </Card>
);

export default LegislatorCard;
