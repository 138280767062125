import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import _ from 'lodash';
import MainHeader from './components/MainHeader';
import Navigation from './components/Navigation';
import LegislatorList from './pages/LegislatorList';
import Legislator from './pages/Legislator';
import Charts from './pages/Charts';
import './App.css';
import data from './config/data/data.json';

const MAX_VOTES = _.maxBy(data, item => parseInt(item.v)).v;

class App extends Component {
  state = {
    page: 'list'
  };

  handleMenuClick = page => this.setState({ page });
  handleLegislatorClick = page => this.setState({ page });

  render() {
    return (
      <div>
        <Navigation
          handleMenuClick={this.handleMenuClick.bind(this)}
          page={this.state.page}
        />
        <Container style={{ textAlign: 'center' }}>
          <MainHeader page={this.state.page} />
          {this.state.page === 'list' && (
            <LegislatorList
              data={data}
              maxVotes={MAX_VOTES}
              handleLegislatorClick={this.handleLegislatorClick.bind(this)}
            />
          )}
          {this.state.page === 'charts' && <Charts />}
          {['list', 'charts'].indexOf(this.state.page) === -1 && (
            <Legislator id={this.state.page} />
          )}
        </Container>
      </div>
    );
  }
}

export default App;
