import React from 'react';
import { Card } from 'semantic-ui-react';
import _ from 'lodash';
import LegislatorCard from '../components/LegislatorCard';

const LegislatorList = ({ data, maxVotes, handleLegislatorClick }) => (
  <Card.Group stackable itemsPerRow={2}>
    {_.orderBy(data, 'nm', 'asc').map(item => (
      <LegislatorCard
        key={item.sqcand}
        item={item}
        maxVotes={maxVotes}
        handleLegislatorClick={handleLegislatorClick}
      />
    ))}
  </Card.Group>
);

export default LegislatorList;
