import React, { Component } from 'react';
import { Image, Menu } from 'semantic-ui-react';
import logo from '../assets/img/logo.png';

class Navigation extends Component {
  render() {
    return (
      <Menu fixed="top">
        <Menu.Item
          key="logo"
          as="a"
          href="https://inteligov.com.br"
          target="_blank"
          header
        >
          <Image src={logo} style={{ margin: '0 auto', height: 20 }} />
        </Menu.Item>
        <Menu.Item
          key="list"
          active={['list', 'leg'].indexOf(this.props.page) > -1 ? true : false}
          onClick={() => this.props.handleMenuClick('list')}
        >
          Parlamentares
        </Menu.Item>
        <Menu.Item
          key="charts"
          active={this.props.page === 'charts' ? true : false}
          onClick={() => this.props.handleMenuClick('charts')}
        >
          Gráficos
        </Menu.Item>
      </Menu>
    );
  }
}

export default Navigation;
