import React, { Component } from 'react';
import { Header, Image /*, Segment */ } from 'semantic-ui-react';
// import axios from 'axios';
import data from '../config/data/data.json';

class Legislator extends Component {
  render() {
    const item = data.filter(x => x.sqcand === this.props.id)[0];
    return (
      <div>
        <div style={{ textAlign: 'center' }}>
          <Image
            src={item.candidateData.fotoUrl}
            style={{
              border: 'solid 5px white',
              boxShadow: '0 1px 1px 1px #aaa',
              margin: '0 auto'
            }}
            rounded
          />
        </div>
        <Header style={{ margin: '10px 0', fontSize: 30, fontWeight: 600 }}>
          {item.nm}
        </Header>
        <Header style={{ margin: '10px 0', fontSize: 25, fontWeight: 100 }}>
          {item.candidateData.partido.sigla}
        </Header>
      </div>
    );
  }
}

export default Legislator;
